<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-button v-if="checkPermission(['ADMIN','SrCommunityRule_ALL','SrCommunityRule_SELECT'])" class="filter-item" size="mini" type="primary"  @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" @click="toClearInit">清空</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','SrCommunityRule_ALL','SrCommunityRule_ADD'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="add">新增</el-button>
      <eForm ref="form" :is-add="true" :sites="sites"/>
    </div>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm,searchCommunity },
  props: {
    query: {
      type: Object,
      required: true
    },
    sites:{}
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    },
    toClearInit() {
      this.$parent.toClearInit()
    },
    add() {
      const _this = this.$refs.form
      _this.dialog = true
      _this.getLimitName()
      _this.getCate()
    }
  }
}
</script>
<style scoped lang="less">
  .align-top {
    vertical-align: top;
  }
</style>
