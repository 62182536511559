import request from '@/utils/request'

export function queryRuleOne(params) {
  return request({
    url: '/community/crm/srCommunityRule/getAdminOne',
    method: 'get',
    params
  })
}

export function findLimit(params) {
    return request({
      url: '/community/crm/srCommunityRule/findLimit',
      method: 'get',
      params
    })
}

export function getCate(params) {
    return request({
      url: '/community/crm/srSignalIndexList/getCate',
      method: 'get',
      params
    })
}
export function edit(data) {
    return request({
      url: '/community/crm/srCommunityRule/updateForPlatform',
      method: 'put',
      data
    })
}

export function add(data) {
    return request({
      url: '/community/crm/srCommunityRule',
      method: 'post',
      data
    })
}

export function updateStatus(data) {
  return request({
    url: '/community/crm/srCommunityRule/updateStatus',
    method: 'put',
    data
  })
}
