<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :data="data" :sites="sites" :is-add="false"/>
  </div>
</template>
<script>
import { queryRuleOne } from '@/api/community/srCommunityRule.js'
import checkPermission from '@/utils/permission'
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites:{
      type: Array,
      required: true
    },
  },
  methods: {
    checkPermission,
    to() {
      var parmas = {
        siteId: this.data.siteId
      }
      queryRuleOne(parmas).then(res => {
        const _this = this.$refs.form
        _this.dialog = true
        _this.form = res;
        _this.getLimitName()
        _this.getCate()
      }).catch(err => {

      })

    },
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
