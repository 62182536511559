<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" :sites="sites" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号">
        </el-table-column>
        <el-table-column prop="siteName" label="社区">
        </el-table-column>
        <el-table-column prop="platformFeeModel" label="收费模式">
        </el-table-column>
        <el-table-column prop="sitePriceList" label="订阅价格"/>
        <el-table-column prop="freePeriod" label="免费订阅">
        </el-table-column>
        <el-table-column prop="reason" label="订阅功能">
          <template slot-scope="scope">
            <span>{{scope.row.status ==1?'启用':scope.row.status == 0?'禁用':''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cmd" label="交易员账户限制">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.platformSignalStatus"
              :active-value="1" :inactive-value="0" @change="platformSignalChange($event,scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="symbol" label="跟随者账户限制">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.platformCopyStatus"
              :active-value="1" :inactive-value="0" @change="platformCopyChange($event,scope.row)"
              >
            </el-switch>
          </template>
        </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SrCommunityRule_ALL','SrCommunityRule_EDIT'])" :sites="sites" :data="scope.row" :sup_this="sup_this"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import {updateStatus} from '@/api/community/srCommunityRule.js'
import { getSitesData } from '@/api/cms/srSite'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/subscription/rule/header'
import edit from '@/components/community/subscription/rule/edit'
export default {
  name:'rule',
  components: {
    eHeader,
    edit,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dataTable:[],
      sites: [],
    }
  },
  mounted() {
    this.getSites();
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    getSites() {
      const params = {page:0,size:1000}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    beforeInit() {
      this.url = 'community/crm/srCommunityRule/getAdminRules';
      this.params = {
        page: this.page,
        size: this.size,
      };
      this.query.order && (this.params.order = this.query.order);
      this.query.signalLogin && (this.params.signalLogin = this.query.signalLogin);
      if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
      }
      this.query.userId && (this.params.userId = this.query.userId);
      this.query.login && (this.params.login = this.query.login);
      this.query.startTime && (this.params.startTime = this.parseTime(this.query.startTime));
      this.query.endTime && (this.params.endTime = this.parseTime(this.query.endTime));
      //
      if(this.query.userId){
        if(!Number.isInteger(Number(this.query.userId))){
          this.$message.error('请输入整数的用户id');
          return false;
        }
      }
      return true
    },
    // 交易员账号限制改变
    platformSignalChange(e,row) {
        var params = {
          id:row.id,
          platformSignalStatus: e
        }
        updateStatus(params).then(res => {
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 2500
          })
          this.sup_this.init()
      }).catch(err => {
        console.log(err.response.data.message)
      })
    },
    platformCopyChange(e,row) {
      var params = {
          id: row.id,
          platformCopyStatus: e
        }
         updateStatus(params).then(res => {
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 2500
          })
          this.sup_this.init()
        }).catch(err => {
          console.log(err.response.data.message)
        })
    },
    getStatus(status){
      let text  = '';
      switch (status) {
        /*<!--          Success("成功", 0),-->
        <!--          Fail_Risk("风控策略限制", 1),-->
        <!--          Fail_Risk_Stop("跟单功能已关闭", 101),-->
        <!--          Fail_Risk_MaxLots("交易量超限", 102),-->
        <!--          Fail_Risk_MaxNum("交易笔数超限", 103),-->
        <!--          Fail_Risk_Login_Stop("账户跟单功能已关闭", 104),-->
        <!--          Fail_Risk_Login_MaxLots("账户交易量超限", 105),-->
        <!--          Fail_Mt4("账户及风控设置", 2),-->
        <!--          Fail_TimeOut("网络超时", 3),-->
        <!--          Open_Waiting("待开仓", 4),-->
        <!--          Close_Waiting("待平仓", 5),-->
        <!--          Fail_Symbols("指数产品不能跟单", 6),-->
        <!--          Fail_User_Delete("用户已失效", 7),-->
        <!--          Fail_Other("其它原因", 99);-->*/
        case 0:
          text = '成功';
          break;
        case 1:
          text = '失败(风控策略)';
          break;
        case 2:
          text = '失败(账户及风控设置)';
          break;
        case 3:
          text = '失败(网路超时)';
          break;
        case 4:
          text = '失败(待开仓)';
          break;
        case 5:
          text = '失败(待平仓)';
          break;
        case 6:
          text = '失败(指数产品不能跟单)';
          break;
        case 7:
          text = '失败(用户已失效)';
          break;
        case 99:
          text = '失败（其它原因）';
          break;
        case 102:
          text = '失败（交易量超限）';
          break;
        case 103:
          text = '失败（交易笔数超限）';
          break;
        case 104:
          text = '失败（账户跟单功能已关闭）';
          break;
        case 105:
          text = '失败（账户交易量超限）';
          break;
      }
      return  text;
    },
    toClearInit() {
      this.query = {}
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
